import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import SignupFormPage from "./components/SignupFormPage";
import LoginFormPage from "./components/LoginFormPage";
import { authenticate } from "./store/session";
import Navigation from "./components/Navigation";
import HomePage from "./components/HomePage";
import VideoPage from "./components/VideoPage";
import UserPage from "./components/UserPage";
import SplashPage from "./components/SplashPage/SplashPage";
import SearchResults from "./components/Search/SearchResults";

function App() {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    dispatch(authenticate()).then(() => setIsLoaded(true));
  }, [dispatch]);

  return (
    <>
      <Navigation isLoaded={isLoaded} />
      {isLoaded && (
        <Switch>

          <Route path="/login" >
            <LoginFormPage />
          </Route>

          <Route path="/signup">
            <SignupFormPage />
          </Route>

          <Route path='/videos/:videoId'>
            <VideoPage/>
          </Route>

          <Route path='/users/:userId'>
            <UserPage/>
          </Route>

          <Route path='/results'>  
            <SearchResults/>
          </Route>

          <Route path='/home'>
            <HomePage />
          </Route>
          
          <Route path='/'>
            <SplashPage/>
          </Route>


        </Switch>
      )}
    </>
  );
}

export default App;
